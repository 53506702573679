<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <PartnerToolbar
        v-if="Object.keys(partner).length !== 0"
        :partner="partner"
        closing
      />
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import PartnerToolbar from './PartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    PartnerToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: '49',
      partner: {},
      rows: [],
      banner: [],
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.ecpm
      })
      return totalAmount
    },
  },
  created() {
    this.getPartnerData()
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('RTB partners'),
          to: '/partners/',
        },
        {
          text: this.partner.name,
          active: true,
        },
      ]
    },
    async getPartnerData() {
      const responseData = await useJwt.getPartner(this.$route.params.id)
      this.partner = responseData.data.partner || {}
      this.getPartnerStatsByType()
      this.setBreabcrumbs()
    },
    async getPartnerStatsByType() {
      this.loading = true
      this.rows = null

      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.type) {
        this.typeStats = urlParams.type
      }

      const params = {
        from: getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
        to: getFormattedDate(new Date()),
        partner_id: this.$route.params.id,
        type: this.typeStats,
      }
      const responseData = await useJwt.getPartnerStatsByType(params)
      this.rows = responseData.data.partnerstats || []
      this.loading = false
    },
    changeType() {
      this.$router.replace({
        name: 'dashboard-partner-stats',
        query: { type: this.typeStats },
      })
      this.getPartnerStatsByType()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
